.MuiBreadcrumbs-root {
  padding-left: 0 !important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 1em !important;
}
.css-a643pv-MuiStack-root {
  justify-content: end;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #555 !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #d4d4d4 !important;
}
.MuiDialog-container {
  .MuiDialog-paper {
    border-radius: 10px;
  }
}
.quizTable {
  .MuiTable-root {
    --Sheet-background: white;
    --TableCell-dataBackground: white;
    --joy-fontWeight-lg: bold;
    th {
      border: none !important;
    }
  }
}

.css-a643pv-MuiStack-root > .MuiTextField-root {
  min-width: 200px !important;
}
pre {
  font-family: monospace;
  margin: 0;
}
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  width: 100%;
}
span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
  width: 100%;
}
//react-select overrides
