.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  &[aria-disabled="true"] {
    label,
    input {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  label {
    margin-bottom: 0.5em;
    display: block;
    font-size: 0.9rem;
  }

  input {
    background-color: #e7f6ff;
    color: #708fa0;
    height: 40px;
    border: none;
    padding-left: 1em;
    border-radius: 5px;
    width: 100%;
  }

  p {
    color: red;
    font-size: 0.8rem;
    margin: 0;
    position: relative;
    top: 0.3em;
    left: 0.3em;
    // border: 1px solid blue;
    // margin-top: 0.5em;
  }
}
