// @import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "~utils/vars";

body,
html {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  overflow-y: scroll;
}

* {
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
}

img {
  user-select: none !important;
  --webkit-user-drag: none;
}

a {
  text-decoration: none;
  color: #555;
}

button {
  border: none;
  cursor: pointer;
}
h2 {
  color: #616161;
  font-weight: 500;
  font-size: 1.5rem;
}
:where(ul) {
  list-style: none;
  text-decoration: none;
  padding-inline-start: 0;
}

.withPadding {
  padding-inline: $inlinePadding;
}

.mainWrapper {
  padding-top: $navbarHeight;
}

.flex-row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-loader {
  display: grid;
  place-items: center;
  height: 100vh;
}

@media screen and (max-width: 1200px) {
  .withPadding {
    padding-inline: 5em;
  }
}

@media screen and (max-width: 1200px) {
  .withPadding {
    padding-inline: 4em;
  }
}

@media screen and (max-width: 800px) {
  .withPadding {
    padding-inline: 3em;
  }
}

@media screen and (max-width: 650px) {
  .withPadding {
    padding-inline: 1.3em;
  }
}
.Page-dropdown__value-container {
  background-color: $primaryColor;
  padding: 0.2em 0.5em;
  border-radius: 7px;
  margin-left: 5px;
  .Page-dropdown__single-value {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }
}

.sem-drop-down__menu,
.spec-drop-down__menu,
.institution-drop-down__menu,
.admin-institution-drop-down__menu,
.branch-drop-down__menu,
.drop-down__menu {
  z-index: 11 !important;
}
.barChart {
  text[text-anchor="middle"][dominant-baseline="central"] {
    fill: #fff !important;
    position: absolute;
    top: 0;
  }
  text[text-anchor="start"] {
    font-weight: bold;
    text-transform: capitalize;
  }
}

.admin-institution-drop-down__menu,
.group-drop-down__menu {
  z-index: 15;
  background: #fff;
}

.selectTopics__control {
  background-color: transparent !important;
  border-radius: 10px !important;
}
.rdw-option-wrapper {
  max-width: 22px;
  min-width: 10px !important;

  img {
    width: 100%;
  }
}
.rdw-dropdown-wrapper {
  height: 20px !important;
  font-size: 0.8rem;
}
.public-DraftStyleDefault-block {
  margin: 0 !important;
}
.filter-assigned-quiz {
  &__menu-list {
    max-height: 250px !important;
  }
}
.quizfilter {
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f2f2f2;
    background: #f2f2f2;
    padding-inline: 1rem;
    padding-block: 1rem;
  }
  &::-webkit-scrollbar {
    width: 7px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border: 2px solid #e5e5e5;
    border-radius: 5px;
  }
}
.technicalLabel {
  font-weight: bold;
}
.quiz-institution-drop-down,
.quiz-sem-drop-down,
.quiz-branch-drop-down,
.quiz-spec-drop-down,
.quiz-group-drop-down {
  &__placeholder {
    text-align: left;
  }
  &__value-container {
    font-size: 0.8rem;
    margin-left: 1.5em;
  }
  &__menu {
    margin-top: 0 !important;
    border: none;
    max-height: 13rem;
    overflow: hidden;
    font-size: 0.8rem !important;
    box-shadow: none !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    padding-top: 0.5rem;
  }
  &__single-value {
    color: #1176ae !important;
    text-align: left;
  }
  &__indicators {
    img[alt="search"] {
      position: absolute;
      left: 0;
      margin-left: 0.5rem;
      top: 0.8em;
    }
  }
  &__dropdown-indicator {
    display: none !important;
  }

  &__control {
    border: none !important;
    background: #fff !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
    cursor: text !important;
  }
  &__menu-list {
    max-height: 13rem !important;
    &::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #fff;
    }
    &::-webkit-scrollbar {
      width: 7px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #e5e5e5;
      border: 2px solid #e5e5e5;
      border-radius: 5px;
    }
  }
  &__option {
    &:first-child {
      margin-top: 0.5em;
    }
    border-bottom: 1px solid #ccc;
    font-size: 0.7rem !important;
    color: #000 !important;
    font-weight: 500;
    text-align: left;
  }
}
.Page-dropdown {
  &__menu {
    z-index: 15 !important;
  }
}
.trainerModal {
  .MuiStack-root {
    width: 100% !important;
  }
  .MuiFormHelperText-filled {
    text-align: end;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}
