@import "~utils/vars";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  z-index: 12;

  header {
    margin-bottom: 1em;
    position: sticky;
    top: 0;
    width: 100%;

    span {
      width: 30px;
      height: 30px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(1, 1, 1, 0.1);
      border-radius: 50%;
      cursor: pointer;

      position: absolute;
      right: 0em;
      top: 1em;
    }
  }

  .content {
    background-color: #ffffff;
    min-width: 400px;
    border-radius: 5px;
    padding-inline: 1em;
    padding-bottom: 1em;

    .modalController {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      margin-bottom: 20px;
      p {
        color: $primaryColor;
        font-size: 20px;
        font-weight: 700;
      }
      button {
        border: none;
        outline: none;
        cursor: pointer;
        background-color: #ffffff;
        svg {
          stroke: #1e1e1e;
          stroke-width: 3px;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .backdrop {
    .content {
      min-width: 90%;
      max-width: 95%;
    }
  }
}
