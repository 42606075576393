.wrapper {
  width: 100%;

  label {
    margin-bottom: 0.5em;
    font-size: 0.9rem;
    display: inline-block;
  }

  p {
    color: red;
    font-size: 0.9rem;
    margin-top: 5px;
  }
}
