.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  img {
    max-width: 500px;
    margin-top: 2rem;
    width: 100%;
  }
}
