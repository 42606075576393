@import "~utils/vars";

.wrapper {
  $paddingIn: 4em;
  padding-inline: $paddingIn;
  padding-bottom: 3em;

  display: flex;
  flex-direction: column;
  align-items: center;

  .confirmModalbtns {
    display: flex;
    margin-right: auto;
    width: fit-content;
    gap: 1em;
    margin-bottom: 1em;
    button {
      a {
        color: #fff !important;
      }
    }
  }
  .caption {
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      cursor: pointer;
      // border-radius: 50%;
      svg {
        color: #aaa;
      }
    }
  }
  .error {
    color: red;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    visibility: hidden;
    position: absolute;
    right: 13px;
    top: 41px;
  }
  .showError {
    visibility: visible !important;
  }
  form {
    width: 100%;

    section {
      width: 100%;
      position: relative;
      padding-top: 2.5em;

      .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 2em;

        div:first-child {
          font-weight: 500;
          margin-bottom: 0.2em;
        }

        // div:last-child {
        //   font-size: 0.8rem;
        //   color: rgba(0, 0, 0, 0.3);
        // }
      }

      &:not(:last-child)::after {
        position: absolute;
        content: "";
        height: 2px;
        width: 100%;
        background-color: #f1f1f1;
        left: -$paddingIn;
        width: calc(100% + 2 * $paddingIn);
      }
    }

    .row {
      margin-bottom: 2.3em;
      display: flex;
      justify-content: space-between;
      width: 100%;

      > div:nth-child(2) {
        margin-left: 2em;
      }
    }

    .platforms {
      p {
        padding-left: 1.3em;
      }

      .platform {
        display: flex;
        align-items: center;

        margin-bottom: 1em;
        position: relative;

        &:last-child {
          margin-bottom: 2em;
        }

        .image {
          margin-right: 1.5em;
          img {
            width: 40px;
          }
        }

        input {
          margin-left: 1.5em;
          background-color: #e7f6ff;
          color: #708fa0;
          height: 40px;
          border: none;
          padding-left: 1em;
          border-radius: 5px;
          width: 90%;
        }
      }

      &.social {
        .image {
          margin-top: 6px;
          margin-right: 1em;
        }
      }
    }
    .mainError {
      color: red;
      font-size: 0.9rem;
    }
    footer {
      padding-top: 2em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button {
        background-color: $primaryColor;
        color: white;
        height: 40px;
        width: 8em;
        border-radius: 5px;
        font-weight: 500;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        svg {
          margin-left: 0.5em;
        }
      }
    }
  }
}
