$inlinePadding: 4em;
$navbarHeight: 3.8em;
$bannerHeight: 15em;

$primaryColor: #4280e3;
$orangeColor: #ffa703;
$themeBlue: #1176ae;
$errorTextColor: #e84033;

$codingPlatformCardWith: 26em;

@mixin codingPlatformCard {
  width: $codingPlatformCardWith;
  height: 15em;
  border-radius: 10px;
  padding: 1em;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  background-position: center;
  background-size: cover;
}
