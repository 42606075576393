@import "~utils/vars";

.wrapper {
  width: 100%;
  min-height: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 5em;

  form {
    display: flex;
    flex-direction: column;
    width: 25em;
    box-shadow: 1px 1px 10px rgba(1, 1, 1, 0.1);

    .caption {
      text-align: center;
      margin-bottom: 2em;
      font-weight: 500;
    }

    padding: 2em;
    border-radius: 10px;
    padding-bottom: 2em;

    label {
      font-size: 14px;
      color: rgba(1, 1, 1, 0.7);
      margin-bottom: 5px;
    }

    svg {
      color: $themeBlue;
    }

    input {
      height: 40px;
      border-radius: 5px;
      outline: none;
      border: 1px solid rgb(158, 157, 157);
      margin-bottom: 15px;
      padding: 0 10px;

      transition: all 0.2s;
      &:focus {
        border-color: $themeBlue;
      }
    }
    button {
      width: 100px;
      height: 35px;
      background-color: $themeBlue;
      color: #ffffff;
      border-radius: 4px;
      font-size: 18px;
      font-weight: 500;
      width: 100%;
    }
    .submitBtn {
      margin-top: 1em;
    }
    .error {
      font-size: 13px;
      font-weight: 500;
      color: red;
      text-align: left;
      visibility: hidden;
      margin: 8px 0;
      text-align: center;
      width: 100%;
    }
    .showError {
      visibility: visible !important;
    }
    .passwordWrapper {
      position: relative;
      input {
        width: 100%;
      }
      button {
        position: absolute;
        right: 15px;
        top: 2px;
        width: fit-content;
        background-color: transparent;
        color: $themeBlue;
      }
    }
  }
}
