.wrapper {
  position: relative;
  label {
    margin-bottom: 0.5em;
    display: block;
    font-size: 0.9rem;
  }

  p {
    color: red;
    font-size: 0.9rem;
    margin-top: 0.5em;
    position: absolute;
    bottom: -35px;
  }
}
